
import { Component, Vue } from 'vue-property-decorator';

// Components
import PageHeader from '@/components/page-header.component.vue';

// Logo Images
import castilhoLogo from '../assets/images/castilho_concept_store_logo.png';
import gotaDaguaLogo from '../assets/images/gota_dagua_logo.png';

@Component({
  components: {
    PageHeader
  }
})
export default class PartnersView extends Vue {

  partners = [
    {
      logo: gotaDaguaLogo,
      name: 'Gota Dagua',
      description: this.$t('Views.Partners.GotaDaguaDescription'),
      address: 'Rua Vasco da Gama 20, 2825-838 Trafaria',
      website: 'https://gotadaguasurf.com/',
      contact: 'info@gotadaguasurf.com',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d811.0937600596202!2d-9.241192122795967!3d38.66292813354776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1eca533af24c91%3A0xe8f6446cc9142be8!2sGota%20Dagua%20Surf%20Camp%20Portugal%20-%20Costa%20da%20Caparica%2C%20Lisbon!5e0!3m2!1sen!2spt!4v1653140559482!5m2!1sen!2spt'
    },
    {
      logo: castilhoLogo,
      name: 'Castilho Concept Store',
      description: this.$t('Views.Partners.CastilhoDescription'),
      address: 'Rua Castilho 39 loja 18, piso 1(6ºandar), 1250-048 Lisboa',
      website: 'https://castilhoconceptstore.pt/',
      contact: 'info@castilhoconceptstore.pt',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d326.7203809809219!2d-9.151876379677397!3d38.722925437615174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193370a64c3575%3A0x46ac0f1942bb4b2e!2sR.%20Castilho%2039%2C%201250-096%20Lisboa!5e0!3m2!1sen!2spt!4v1653152119407!5m2!1sen!2spt'
    },
  ]

}
